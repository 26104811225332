@content112: content112;
.@{content112}-wrapper {
  
  background: url("../../../../img/humanresource/humanbg2.png") no-repeat;
  // background-size: cover;
  background-size: 100% 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 40px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        color: #fff;
        font-size: 24px;
      }
    }
  }

  .button {
    box-shadow: 0 8px 16px #0554b7;
    background-color: #fff;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    border: 0;
    border-radius: 21px;
    color: #3981EE;
    width: 128px;
    padding: 0 15px;
    display: inline-block;
    transition: transform .3s, box-shadow .3s;
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 12px 20px #0554b7;
    }
    &:active {
      transform: translateY(4px);
      box-shadow: 0 4px 8px #0554b7;
    }
  }
  .title-content {
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content112}-wrapper {
    padding-bottom: 0;
  }
}

.content112-bottom-img {
  padding-bottom: 70px;
}

.content112-bottom-text {
  font-size: 16px;
  color: #fff;
  width: 150px;
  margin-left: 70px;
}

.content112-bottom-line {
  position: relative;
  bottom: 120px;
  padding-bottom: 50px;
}

.content112-bottom-wrapper {
  width: 300px;
  text-align: center;
  margin-top: 50px;
}