
@content133: content133;
.@{content133}-wrapper {
  min-height: 380px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  
  margin: 0 auto;
  overflow: hidden;
  padding: 70px 0 70px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 10px;
    padding-bottom: 80px;
    color: #7B8390;
    font-size: 18px;
  }
}

@media screen and (max-width: 967px) {
  .@{content133}-wrapper {
    padding-bottom: 0;
  }
}

.content133-solution-wrapper {
  padding: 30px 20px;
  background:rgba(255,255,255,1);
  box-shadow:8px 4px 30px 0px rgba(0,0,0,0.1);
  border-radius:14px;
  height: 270px;
  // margin-right: 150px;
  // position: relative;
  // right: 300px;
  margin-right: 15%;
  position: relative;
  left: 58%;
}

.content133-img-wrapper {
  display: flex;
  justify-content: center;
}

.content133-solution-img {
  width:76px;
  height:76px;
  margin-top: 10px;
}

.content133-solution-title {
  font-size:24px;
  text-align: center;
  color: #4A4A4A;
  padding-bottom: 10px;
}

.content133-solution-content {
  color:rgba(74,74,74,1);
  line-height:20px;
  font-size: 14px;
  text-align: left;
  text-indent: 28px;
}

.content133-slider-wrapper {
  padding-bottom: 30px;
}

.content133-item-wrapper {
  width: 520px;
  height: 200px;
  border-radius:2px;
  display: flex;
  border:1px solid rgba(241,241,241,1);
  padding: 10px 10px;
  box-shadow:0px 2px 16px 0px rgba(0,0,0,0.08);
}

@media screen and (max-width: 767px) {
  .content133-item-wrapper {
    width: 100%;
    height: auto;
  }
}


.content133-news {
  display: inline-block;
  padding-top: 10px;
  text-align: left;
  padding-left: 15px;;
}


.content133-news-title {
  font-size: 18px;
  color: #000000;
  margin-bottom: 10px;
  padding-top: 50px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
}

.content-news-text {
  font-size: 14px;
  color: #686868;
}

.content133-bg1 {
  background: url("../../../../img/humanresource/01.png") no-repeat left;
}

.content133-bg2 {
  background: url("../../../../img/humanresource/02.png") no-repeat left;
}

.content133-bg3 {
  background: url("../../../../img/humanresource/03.png") no-repeat left;
}

.content133-bg4 {
  background: url("../../../../img/humanresource/04.png") no-repeat left;
}
