@content1313: content1313;
.@{content1313}-wrapper {
  height: 880px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  .ant-tabs-tab-active {
    background-color: #418FFF;
    color: #fff!important;
  }
  
  .ant-tabs-tab {
    margin-right: 0!important;
    padding-right: 32px!important;
    padding-left: 30px!important;
  }
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content1313}-wrapper {
    padding-bottom: 0;
  }
}

.content1313-breadcrumb {
  text-align: left;
  margin-top: 30px;
}

.content1313-pagination {
  margin-top: 60px;
  margin-left: 400px;
}
.content1313-pagination-mobile {
  margin-top: 60px;
  margin-left: 100px;
}

.ant-tabs-tab-active {
  background-color: #418FFF;
  color: #fff!important;
  .ant-tabs-tab-btn{
    color: #fff!important;
  }
}

.content1313-right {
  width: 60vw;
  margin-left: 5vw;
}

.content1313-item {
  text-align: left;
  background: url("../../../img/news/sanjiao.png") no-repeat ;
  background-position: 0px 3px ;
  margin-top: 10px;
  cursor: pointer;
}

.content1313-item:hover {
  background: url("../../../img/news/bluearrow.png") no-repeat ;
  background-position: 0px 3px ;
}

.content1313-item:hover .content1313-item-title {
  color: #418FFF;
}

.content1313-item:hover .content1313-item-time {
  color: #418FFF;
}

.content1313-item-title {
  margin-left: 20px;
  color: #404040;
  font-size:16px;
  width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  display: inline-block;
}

.content1313-item-title-mobile {
  margin-left: 20px;
  color: #404040;
  font-size:16px;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  display: inline-block;
}

.content1313-item-time {
  float: right;
  color: #999999;
  font-size:14px;
}

.content1313-title-h1 {
  text-align: left;
  margin-bottom: 20px;
  margin-left: 5px;
  font-size: 20px;
  color: #404040;
}




.content1313-none {
  display: none
}

.content1313-goback {
  font-size: 32px;
  margin-right: 30px;
  position: relative;
  top: 3px;
  color: #000;
}

.content1313-article-title {
  font-size:24px;
  color: #404040;
  padding: 20px 0;
  text-align: center;
}

.content1313-article-sub-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.content1313-article-time {
  color: #666666;
  font-size:14px;
  padding: 20px 0;
  text-align: center;
}

.content1313-article-num {
  color: #666666;
  font-size:14px;
  padding: 20px 0;
  text-align: center;
}

.content1313-article-bottom {
  margin-bottom: 76px;
  width: 1000px;
  img {
    max-width: 100%;
  }
}

.content1313-article-bottom-mobile {
  margin-bottom: 76px;
  width: 300px;
}

.content1313-downfile-wrapper {
  border: 1px dashed #C7C7C7;
  margin-bottom: 30px;
  padding: 0 30px 10px 30px;
}

.content1313-downfile {
  padding-top: 10px;
}

.content1313-width {
  width: 1000px;
  margin-left: 0!important;
}

.content1313-width-mobile {
  width: 300px;
  margin-left: 0!important;
}

