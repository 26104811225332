@banner35: banner35;
.@{banner35} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 450px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("../../../img/about/banner.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  & &-text-wrapper {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: @template-text-color-light;
    max-width: 845px;
    height: 500px;
    width: 80%;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    padding-top: 100px;
    >.queue-anim-leaving {
      position: relative !important;
    }
  }
  & &-slogan {
    font-size: 54px;
    line-height: 80px;
    text-indent: 2px;
    font-weight: 500;
    margin: 56px auto 38px;
    overflow: hidden;
  }
  & &-name-en {
    display: block;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
  }
  & &-name {
    font-size: 24px;
    overflow: hidden;
    opacity: 0.8;
  }
  & &-button {
    margin-top: 40px;
    color: #fff;
    border-radius:20px;
    transition: background .45s @ease-out, box-shadow .45s @ease-out;
    line-height: 36px;
    font-size: 16px;
    width:156px;
    height:45px;
    background-color: #FF9B2B!important;
    & span {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transition: text-shadow .45s @ease-out;
    }
    &:hover {
      color: #fff;
      border-color: #FF9B2B;
      background: #FF9B2B;
      transition: background .45s @ease-out, border .45s @ease-out;
      & span {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .35);
      }
    }
  }
  & &-time {
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .@{banner35} {
    background-attachment: inherit;
    & &-text-wrapper {
      width: 90%;
      height: 50%;
    }
    & &-name-en {
      font-size: 12px;
    }
    & &-slogan {
      font-size: 24px;
      line-height: 1.5;
      margin: 12px 0;
    }
    & &-name {
      font-size: 14px;
    }
  }
}

.banner35-item-wrapper {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  top: 110px;
  z-index: 999;
}

.banner35-wrapper-left {
  text-align: center;
}

.banner35-item {
  padding: 25px 0;
  background-color: #FAFBFC;
}

.banner35-item-mobile {
  padding: 25px 0;
  width: 30%;
  background-color: #FAFBFC;
}

.banner35-item-img {
  
}

.banner35-item-text {
  color: #595959;
  font-size:14px;
}

.banner35-clicked {
  background-color: #fff;
  border-bottom: 2px solid #418FFF;
}

.banner35-none {
  display: none;
}