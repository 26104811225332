@content1310: content1310;
.@{content1310}-wrapper {
  min-height: 380px;
  // background: url('../../../../img/systembg.png') no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        background: url("../../../img/about/company.png") no-repeat;
        background-position: center 10px;
        padding: 20px 0 0 0;
      }
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) {
  .@{content1310}-wrapper {
    padding-bottom: 0;
  }
}

.content1310-left-item {
  text-align: right;
  position: relative;
  bottom: 5px;
}

.content1310-right-item {
  text-align: left;
  position: relative;
  bottom: 5px;
  
}

.content1310-time {
  font-size:18px;
  color: #404040;
  
}

.content1310-honor {
  font-size:14px;
  color: #7B8390;
  display: inline-block;
  margin-bottom: 39px;
}

.content1310-left {
  margin-top: 50px;
  margin-left: 8vw;
}

.content1310-right {
  position: relative;
  bottom: 5px;
}

.content1310-timeline-wrapper {
  padding: 0 20px;
}