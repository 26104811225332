@content138: content138;
.@{content138}-wrapper {
  min-height: 380px;
  background: url("../../../../img/file/bg1.png") no-repeat ;
  background-size: cover;
  background-size: 100%;
  background-color: #EDF1F5;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0 36px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .@{content138}-wrapper {
    padding-bottom: 0;
  }
}

.content138-arrow-left {
  position: relative;
  right: 45%;
  top: 200px;
}

.content138-arrow-right {
  position: relative;
  left: 45%;
  bottom: 200px;
}

.content138-slider-img {
  position: relative;
  right: 250px;
}

// .content138-slider-img-middle {
//   position: relative;
//   left: 50px;
// }

// .content138-slider-img-right {
//   position: relative;
//   left: 50px;
// }

.content138-slider-wrapper {
  width: 80%;
  position: relative;
  left: 10%;
}

.content138-expalin {
  color: #404040;
}