@content7: content7;
.@{content7}-wrapper {
  
  .@{content7} {
    // >h1,
    // >p {
    //   text-align: center;
    // }
    // &-tag {
    //   & i {
    //     width: 12px;
    //     height: 14px;
    //     display: inline-block;
    //     vertical-align: middle;
    //     margin-right: 5px;
    //   }
    //   &-name {
    //     display: inline-block;
    //   }
    // }
    // .ant-tabs-bar {
    //   text-align: center;
    // }
    // .ant-tabs {
    //   .ant-tabs-nav {
    //     float: none;
    //     text-align: center;
    //   }
    // }
    // &-tabs-wrapper {
    //   position: relative;
    //   margin-top: 24px;
    // }
    // &-content {
    //   display: flex;
    //   align-items: center;
    // }
    // &-text {
    //   padding: 24px 48px;
    // }
    // &-img {
    //   padding: 24px 48px;
    // }
    // .ant-tabs-tabpane {
    //   margin-top: 40px;
    // }
  }
}

@media screen and (max-width: 767px) {
  .@{content7}-wrapper {
    // min-height: 980px;
    // overflow: hidden;
    // .@{content7} {
    //   max-width: 100%;
    //   &-content {
    //     display: block;
    //   }
    //   &-text,
    //   &-img {
    //     text-align: left;
    //     padding: 0;
        
    //   }
    //   .ant-tabs-bar {
    //     width: auto;
    //     .ant-tabs-nav {
    //       float: left;
    //     }
    //   }
    // }
  }
}

.content7-bottom-text {
  text-align: left;
  color: #666666;
  line-height:20px;
  padding-bottom: 50px;
}

.content7-bottom-img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.content7-bottom-wrapper {
  // width: 740px;
}

.content7-bottom-item {
  color: #292929;
  font-size: 14px;
  text-align: left;
  padding: 15px 0 15px 20px;
  width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  display: inline-block;
}

.content7-bottom-time {
  width: 200px;
  font-size: 14px;
  color: #999999;
}

.content7-explain {
  padding-bottom: 60px;
  color: #7B8390;
  font-size: 18px;
}

.content7-item-wrapper {
  display: flex;
  align-items: center;
  box-shadow:0px 1px 0px 0px rgba(233,235,243,1);
  background: url("../../../img/home/arrow.png") no-repeat;
  background-position: 0px 18px;
  cursor: pointer;
}

.content7-item-wrapper:hover {
  background: url("../../../img/home/sanjiao.png") no-repeat;
  background-position: 0px 18px;
}

.content7-item-wrapper:hover .content7-bottom-item {
  color: #418FFF;
}

.content7-item-wrapper:hover .content7-bottom-time {
  color: #418FFF;
}

.content7-more {
  color: #418FFF;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 2%;
  z-index: 999;
}

.content7-left {
  position: relative;
  
  
}

.content7-control-width {
  width: 740px;
  margin-right: 50px;
  .ant-tabs-tab-active {
    background-color: #418FFF;
    color: #fff!important;
    .ant-tabs-tab-btn{
      color: #fff!important;
    }
  }
  
  .ant-tabs-tab {
    margin-right: 0!important;
    padding-right: 32px!important;
    padding-left: 30px!important;
  }
}

.content7-right {
  width: 340px;
}

.content7-right-item-title {
  font-size:14px;
  color: #404040;
  padding-bottom: 5px;
}

.content7-right-item-test {
  display: block;
  text-align: center;
  font-size: 24px;
  color: #fff;
  // width: 340px;
  height: 93px;
  background: url("../../../img/home/testlink.png") no-repeat;
  background-size: 340px 93px;
  padding-top: 25px;
  padding-left: 10px;
  position: relative;
}

.content7-right-item-text {
  background: rgba(65,143,255,0.42);
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  width: 100%;
  font-size: 14px;
  height: 33px;
  padding-top: 5px;
  text-align: left;
  padding-left: 30px;
  >span {
    z-index: 999;
  }
}

.content7-right-item-wrapper {
  text-align: left;
}

.content7-right-item-more {
  text-align: right;
  color: #418FFF;
  cursor: pointer;
}

.content7-right-item-img {
  display: block;
}

.content7-right-item-title {
  display: flex;
  justify-content: space-between;
}

.content7-right-item-bottom {
  display: flex;
}

.content7-right-hotpos-text {
  font-size: 14px;
  color: #303030;
}

.content7-right-item-arrow {
  margin: 0 8px;
}

.content7-right-item-items {
  padding-top: 5px;
}

.content7-wrapper-control {
  height: 750px;
  padding: 56px 0 30px 0;
}