@content136: content136;
.@{content136}-wrapper {
  min-height: 380px;
  // background: url('../../../../img/systembg.png') no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) {
  .@{content136}-wrapper {
    padding-bottom: 0;
  }
}

.content136-system-left {

}

.content136-system-right {

}

.content136-text-wrapper {
  text-align: left;
  padding: 50px 30px;
}

.content136-text-title1 {
  font-size:24px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(13,26,38,1);
  line-height:33px;
  padding-bottom: 20px;
}

.content136-text-title2 {
  font-size:14px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(49,70,89,1);
  line-height:20px;
  padding: 20px;
}

.content136-text-content {
  font-size:14px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color: #7E8393;
  line-height:22px;
  text-indent: 28px;
}

.content136-right-img {
 
}

.content136-text-blue {
  width:4px;
  height:24px;
  background: #418FFF;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 20px;
}