@content1311: content1311;
.@{content1311}-wrapper {
  min-height: 380px;
  // background: url('../../../../img/systembg.png') no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        background: url("../../../img/about/company.png") no-repeat;
        background-position: center 10px;
        padding: 20px 0 0 0;
      }
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) {
  .@{content1311}-wrapper {
    padding-bottom: 0;
  }
}

.content1311-system-left {

}

.content1311-system-right {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 50px;
}

.content1311-item-wrapper {
  width: 200px;
  text-align: left;
  height: 150px;
}

.content1311-item-img {

}

.content1311-item-title {
  color: #404040;
  font-size:16px;
}

.content1311-item-text {
  font-size:14px;
  color: #7E8393;
  position: relative;
  bottom: 30px;
  padding: 0 0 30px 30px
}

.content1311-total-wrapper {
  
}

.content1311-item-none {
  display: none;
}

.content1311-position-right {
  padding-left: 30px;
}