
@content134: content134;
.@{content134}-wrapper {
  min-height: 380px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  
  margin: 0 auto;
  overflow: hidden;
  padding: 70px 0 70px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 10px;
    padding-bottom: 80px;
    color: #7B8390;
    font-size: 18px;
  }
}

@media screen and (max-width: 967px) {
  .@{content134}-wrapper {
    padding-bottom: 0;
  }
}

// .content134-solution-wrapper {
//   padding: 30px 20px;
//   background:rgba(255,255,255,1);
//   box-shadow:8px 4px 30px 0px rgba(0,0,0,0.1);
//   border-radius:14px;
//   height: 270px;
//   // margin-right: 150px;
//   // position: relative;
//   // right: 300px;
//   margin-right: 15%;
//   position: relative;
//   left: 58%;
// }

// .content134-img-wrapper {
//   display: flex;
//   justify-content: center;
// }

// .content134-solution-img {
//   width:76px;
//   height:76px;
//   margin-top: 10px;
// }

// .content134-solution-title {
//   font-size:24px;
//   text-align: center;
//   color: #4A4A4A;
//   padding-bottom: 10px;
// }

// .content134-solution-content {
//   color:rgba(74,74,74,1);
//   line-height:20px;
//   font-size: 14px;
//   text-align: left;
//   text-indent: 28px;
// }

// .content134-slider-wrapper {
//   padding-bottom: 30px;
// }

// .content134-item-wrapper {
//   width:520px;
//   height:120px;
//   border-radius:2px;
//   display: flex;
//   border:1px solid rgba(241,241,241,1);
//   padding: 10px 10px;
// }

// .content134-item-wrapper:hover {
//   background:rgba(255,255,255,1) linear-gradient(176deg,rgba(250,195,68,1) 0%,rgba(255,172,48,1) 100%);
// }

// .content134-item-wrapper:hover .content134-news-title {
//   color: #fff;
// }

// .content134-item-wrapper:hover .content134-news-text {
//   color: #fff;
// }

// .content134-item-wrapper:hover .content134-left {
//   color: #fff;
// }

// .content134-left {
//   box-shadow: 1px 0px 0px 0px rgba(241,241,241,1);
//   display: inline-block;
//   width: 110px;
//   padding-right: 30px;
//   color: #999999;
// }

// .content134-right {
//   display: inline-block;
//   padding-top: 10px;
//   text-align: left;
//   padding-left: 15px;;
// }

// .content134-time-day {
//   font-size: 48px;
// }

// .content134-time-year {
//   font-size: 14px;
// }

// .content134-news-title {
//   font-size: 16px;
//   color: #383838;
//   margin-bottom: 10px;
// }

// .content-news-text {
//   font-size: 14px;
//   color: #999999;
// }

// .content134-more {
//   color: #FF9228;
//   font-size: 14px;
//   margin-top: 50px;
// }




.content134-item {
  padding: 20px 20px;
  width: 278px;
  height: 228px;
  box-shadow:0px 2px 16px 0px rgba(0,0,0,0.08),-2px 0px 0px 0px #418FFF;
}

.content134-item:hover {
  background-color: #418FFF;
  color: #fff!important;
  width:580px;
  height:228px;
}

.content134-item:hover .content134-item-text {
  display: block;
}

.content134-item:hover .content134-item-img2 {
  display: block;
}

.content134-item:hover .content134-item-img1 {
  display: none;
}

.content134-item:hover .content134-item-title {
  color: #fff;
}

.content134-item-title {
  text-align: left;
  color: #596075;
  font-weight: 500;
  font-size: 16px;
}

.content134-item-img1 {
  display: block;
  float: right;
  padding-top: 50px;
  padding-right: 10px;
}

.content134-item-img2 {
  display: none;
  position: relative;
  left: 400px;
  bottom: 60px;
}

.content134-item-text {
  display: none;
  padding-top: 20px;
  width: 350px;
  text-align: left;
  font-size: 14px;
  height: 130px;
}