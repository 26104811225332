@content113: content113;
.@{content113}-wrapper {
  height: 480px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  background-color: #F2F5F9;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 55px;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      width: 1200px;
      background-color: #fff;
      padding: 30px 100px 60px 100px;
    }
  }
  .button {
    // box-shadow: 0 8px 16px #0554b7;
    // background: linear-gradient(to right, #05cbff, #1e5aff) !important;
    position: relative;
    bottom: 120px;
    background-color: #418FFF;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    border: 0;
    border-radius: 21px;
    color: #fff;
    width: 128px;
    padding: 0 15px;
    display: inline-block;
    transition: transform .3s, box-shadow .3s;
    &:hover {
      transform: translateY(-4px);
      // box-shadow: 0 12px 20px #0554b7;
    }
    &:active {
      transform: translateY(4px);
      // box-shadow: 0 4px 8px #0554b7;
    }
  }
  .title-content {
    line-height: 32px;
    color: #666666;
  }
  .title-content2 {
    line-height: 25px;
    text-align: left;
    text-indent: 32px;
    color: #737C8B;
    padding-top: 30px;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .@{content113}-wrapper {
    padding-bottom: 0;
  }
}

.content113-about {
  width:156px;
  height:40px;
  background:linear-gradient(136deg,rgba(130,181,255,1) 0%,rgba(60,137,246,1) 100%);
  box-shadow:0px 9px 22px 0px rgba(95,155,241,0.35);
  border-radius:20px;
  color: #fff;
  padding-top: 9px;
  margin-left: 400px;
  margin-top: 50px;
}