@content1312: content1312;
.@{content1312}-wrapper {
  min-height: 380px;
  // background: url('../../../../img/systembg.png') no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        background: url("../../../img/about/company.png") no-repeat;
        background-position: center 10px;
        padding: 20px 0 0 0;
      }
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) {
  .@{content1312}-wrapper {
    padding-bottom: 0;
  }
}



.content1312-left-blue {
  width:3px;
  height:20px;
  background: #418FFF;
  display: inline-block;
  position: relative;
  top: 5px;
  right: 10px;
}

.content1312-item {
  width:330px;
  box-shadow:0px 2px 15px 0px rgba(98,98,98,0.2);
  text-align: left;
  padding: 20px 20px 20px 35px!important;
  margin-right: 20px;
  margin-top: 30px;
}

.content1312-item-title {
  font-size: 16px;
  color: #404040;
  position: relative;
  right: 4px;
  padding-bottom: 20px;
}

.content1312-item-text1 {
  color: #7E8393;
  font-size: 14px;
}

.content1312-item-text2 {
  color: #7E8393;
  font-size: 14px;
}

.content1312-item-text3 {
  color: #7E8393;
  font-size: 14px;
}

.content1312-item-text4 {
  color: #7E8393;
  font-size: 14px;
}

.content1312-num {
  padding-left: 63px;
}

.content1312-block {
  
}

.content1312-none {
  display: none;
}

.content1312-down {
  color: #418FFF;
  cursor: pointer;
  margin-left: 46vw;
}

.content1312-up {
  color: #418FFF;
  cursor: pointer;
  margin-left: 46vw;
}