body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: rgba(0, 0, 0, 0);
  width: 100%;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  border-bottom: 5px solid #fff;
  display: inline-block;
  width: 100px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  background-color: #418FFF;
  opacity: 0.85;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #1890ff;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.nav2-productlink {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.nav2-specitem:hover {
  color: #418FFF;
}
.header2-trans-wrapper {
  z-index: 999;
  position: absolute;
  top: 0;
}
.header2-blue-wrapper {
  background-color: #418FFF;
}
.header2-default-bgcolor {
  background-color: #418FFF !important;
}
.banner35 {
  width: 100%;
  height: 450px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("../../../img/about/banner.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner35 .banner35-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding-top: 100px;
}
.banner35 .banner35-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner35 .banner35-slogan {
  font-size: 54px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 500;
  margin: 56px auto 38px;
  overflow: hidden;
}
.banner35 .banner35-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner35 .banner35-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner35 .banner35-button {
  margin-top: 40px;
  color: #fff;
  border-radius: 20px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  width: 156px;
  height: 45px;
  background-color: #FF9B2B !important;
}
.banner35 .banner35-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner35 .banner35-button:hover {
  color: #fff;
  border-color: #FF9B2B;
  background: #FF9B2B;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner35 .banner35-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner35 .banner35-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner35 {
    background-attachment: inherit;
  }
  .banner35 .banner35-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner35 .banner35-name-en {
    font-size: 12px;
  }
  .banner35 .banner35-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner35 .banner35-name {
    font-size: 14px;
  }
}
.banner35-item-wrapper {
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  top: 110px;
  z-index: 999;
}
.banner35-wrapper-left {
  text-align: center;
}
.banner35-item {
  padding: 25px 0;
  background-color: #FAFBFC;
}
.banner35-item-mobile {
  padding: 25px 0;
  width: 30%;
  background-color: #FAFBFC;
}
.banner35-item-text {
  color: #595959;
  font-size: 14px;
}
.banner35-clicked {
  background-color: #fff;
  border-bottom: 2px solid #418FFF;
}
.banner35-none {
  display: none;
}
.content114-wrapper {
  background-size: 100% 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 100px 0 40px 0;
}
.content114-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content114-wrapper.home-page-wrapper .title-wrapper .title-h1 {
  background: url("../../../img/about/company.png") no-repeat;
  background-position: center 10px;
  padding: 20px 0 0 0;
}
.content114-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background-color: #fff;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #3981EE;
  width: 128px;
  padding: 0 15px;
  display: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content114-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content114-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content114-wrapper .title-content {
  line-height: 32px;
}
.content114-wrapper .title-content2 {
  width: 70vw;
  margin-left: 15vw;
  text-align: left;
  text-indent: 28px;
  color: #777777;
  line-height: 24px;
  padding-top: 40px;
}
.content114-wrapper .title-content3 {
  width: 70vw;
  margin-left: 15vw;
  text-align: left;
  text-indent: 28px;
  color: #777777;
  padding-top: 20px;
  line-height: 24px;
}
@media screen and (max-width: 767px) {
  .content114-wrapper {
    padding-bottom: 0;
  }
}
.content139-wrapper {
  min-height: 380px;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 36px 0;
}
.content139-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content139-wrapper.home-page-wrapper .title-wrapper .title-h1 {
  background: url("../../../img/about/company.png") no-repeat;
  background-position: center 10px;
  padding: 20px 0 0 0;
}
.content139-wrapper .title-content {
  line-height: 32px;
  padding-top: 70px;
}
@media screen and (max-width: 767px) {
  .content139-wrapper {
    padding-bottom: 0;
  }
}
.content139-text-wrapper {
  text-align: left;
  padding: 50px 30px;
}
.content139-text-title1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0d1a26;
  line-height: 33px;
  padding-bottom: 20px;
}
.content139-text-title2 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #314659;
  line-height: 20px;
  padding: 20px;
}
.content139-text-content {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  line-height: 22px;
  padding-top: 20px;
}
.content139-text-blue {
  width: 4px;
  height: 24px;
  background: #FF8610;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 20px;
}
.content115-wrapper {
  background: url("../../../img/bg3.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 40px 0;
}
.content115-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content115-wrapper.home-page-wrapper .title-wrapper .title-h1 {
  color: #fff;
  font-size: 48px;
}
.content115-wrapper.home-page-wrapper .title-wrapper .title-content {
  font-size: 18px;
  color: #fff;
}
.content115-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background-color: #fff;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #3981EE;
  width: 128px;
  padding: 0 15px;
  display: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content115-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content115-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content115-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content115-wrapper {
    padding-bottom: 0;
  }
}
.content1310-wrapper {
  min-height: 380px;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content1310-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content1310-wrapper.home-page-wrapper .title-wrapper .title-h1 {
  background: url("../../../img/about/company.png") no-repeat;
  background-position: center 10px;
  padding: 20px 0 0 0;
}
.content1310-wrapper .title-content {
  line-height: 32px;
  padding-top: 70px;
}
@media screen and (max-width: 767px) {
  .content1310-wrapper {
    padding-bottom: 0;
  }
}
.content1310-left-item {
  text-align: right;
  position: relative;
  bottom: 5px;
}
.content1310-right-item {
  text-align: left;
  position: relative;
  bottom: 5px;
}
.content1310-time {
  font-size: 18px;
  color: #404040;
}
.content1310-honor {
  font-size: 14px;
  color: #7B8390;
  display: inline-block;
  margin-bottom: 39px;
}
.content1310-left {
  margin-top: 50px;
  margin-left: 8vw;
}
.content1310-right {
  position: relative;
  bottom: 5px;
}
.content1310-timeline-wrapper {
  padding: 0 20px;
}
.content1311-wrapper {
  min-height: 380px;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content1311-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content1311-wrapper.home-page-wrapper .title-wrapper .title-h1 {
  background: url("../../../img/about/company.png") no-repeat;
  background-position: center 10px;
  padding: 20px 0 0 0;
}
.content1311-wrapper .title-content {
  line-height: 32px;
  padding-top: 70px;
}
@media screen and (max-width: 767px) {
  .content1311-wrapper {
    padding-bottom: 0;
  }
}
.content1311-system-right {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 50px;
}
.content1311-item-wrapper {
  width: 200px;
  text-align: left;
  height: 150px;
}
.content1311-item-title {
  color: #404040;
  font-size: 16px;
}
.content1311-item-text {
  font-size: 14px;
  color: #7E8393;
  position: relative;
  bottom: 30px;
  padding: 0 0 30px 30px;
}
.content1311-item-none {
  display: none;
}
.content1311-position-right {
  padding-left: 30px;
}
.content1312-wrapper {
  min-height: 380px;
  background-size: cover;
  background-size: 100% 100%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content1312-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content1312-wrapper.home-page-wrapper .title-wrapper .title-h1 {
  background: url("../../../img/about/company.png") no-repeat;
  background-position: center 10px;
  padding: 20px 0 0 0;
}
.content1312-wrapper .title-content {
  line-height: 32px;
  padding-top: 70px;
}
@media screen and (max-width: 767px) {
  .content1312-wrapper {
    padding-bottom: 0;
  }
}
.content1312-left-blue {
  width: 3px;
  height: 20px;
  background: #418FFF;
  display: inline-block;
  position: relative;
  top: 5px;
  right: 10px;
}
.content1312-item {
  width: 330px;
  box-shadow: 0px 2px 15px 0px rgba(98, 98, 98, 0.2);
  text-align: left;
  padding: 20px 20px 20px 35px!important;
  margin-right: 20px;
  margin-top: 30px;
}
.content1312-item-title {
  font-size: 16px;
  color: #404040;
  position: relative;
  right: 4px;
  padding-bottom: 20px;
}
.content1312-item-text1 {
  color: #7E8393;
  font-size: 14px;
}
.content1312-item-text2 {
  color: #7E8393;
  font-size: 14px;
}
.content1312-item-text3 {
  color: #7E8393;
  font-size: 14px;
}
.content1312-item-text4 {
  color: #7E8393;
  font-size: 14px;
}
.content1312-num {
  padding-left: 63px;
}
.content1312-none {
  display: none;
}
.content1312-down {
  color: #418FFF;
  cursor: pointer;
  margin-left: 46vw;
}
.content1312-up {
  color: #418FFF;
  cursor: pointer;
  margin-left: 46vw;
}
.divide {
  height: 1px;
}
.footer1-wrapper {
  background: #2E3748;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
  margin-right: 20px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
  text-align: center;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 4px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 4px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  padding: 20px 0;
  height: 80px;
  text-align: center;
  line-height: 20px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper {
    background-color: #2D313B;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.footer1-relavant {
  font-size: 18px;
  color: #D8D8D8;
  padding-bottom: 20px;
  text-align: left;
}
.footer1-relavant-text {
  color: #999 !important;
  font-size: 12px;
}
.footer1-number {
  font-size: 36px;
  color: #fff;
}
.footer1-time {
  font-size: 16px;
  color: #fff;
  padding-top: 20px!important;
}
.footer1-hotline {
  font-size: 16px;
  color: #fff;
  padding-top: 50px;
  display: inline-block;
}
.footer1-wx {
  font-size: 16px;
  color: #CCCCCC;
}
.footer1-logoimg {
  padding-bottom: 10px;
}
.footer1-text-center {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 90vw;
  color: #999 !important;
}
.footer1-relavant-center {
  text-align: center;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .k8y754vyvl6-editor_css {
  line-height: 0px;
}
