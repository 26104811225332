@content132: content132;
.@{content132}-wrapper {
  min-height: 380px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 16px 0 30px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        background: url("../../../img/home/cooperation.png") no-repeat;
        background-position: center 10px;
        padding: 20px 0 0 0;
      }
    }
  }
  .title-content {
    line-height: 32px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .@{content132}-wrapper {
    padding-bottom: 0;
  }
}

.content132-arrow-left {
  position: relative;
  right: 45%;
  top: 123px;
}

.content132-arrow-right {
  position: relative;
  left: 45%;
  bottom: 182px;
}

.content132-slider-img {
  display: inline-block;
  margin-left: 0.6%;
}

.content132-slider-wrapper {
  width: 80%;
  padding-bottom: 50px;
  position: relative;
  left: 10%;
}

.content132-expalin {
  color: #404040;
}

.content132-title-explain {
  padding-bottom: 30px;
  color: #7B8390;
  font-size: 18px;
}

.content132-img-wrapper {

}