@content116: content116;
.@{content116}-wrapper {
  // margin: 0 auto;
  overflow: hidden;
  padding: 20px 0 0 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        color: #fff;
        font-size: 24px;
      }
    }
  }

  .title-content {
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .@{content116}-wrapper {
    padding-bottom: 0;
  }
}

.content116-test-img {
  width: 280px;
  height: 140px;
}

.content116-test-img-mobile {
  width: 100px;
  height: 170px;
}


.content116-new-title {
  font-size: 24px;
}

.content116-icon {
  width: 28px;
  margin-left: 3px;
}

.content116-more {
  font-size: 14px;
  color: #418FFF;
  cursor: pointer;
  position: relative;
  top: 16px;
}

.content116-top-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.content116-content-wrapper {
  width: 1200px;
}

.content116-bottom-wrapper {
  display: flex;
  justify-content: space-between;
}