
@content1314: content1314;
.@{content1314}-wrapper {
  min-height: 100px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  background-color: #418fff;
  margin: 0 auto;
  overflow: hidden;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      &.title-h1 {
        display: none;
      }
    }
  }
  .title-content {
    color: #fff;
    font-size: 16px;
    margin-top: 35px;
  }
}

@media screen and (max-width: 967px) {
  .@{content1314}-wrapper {
    padding-bottom: 0;
  }
}

.content1314-news {
  display: none;
}

.content1314-about {
  display: inline-block;
  width: 100px;
  text-align: center;
  border: 1px solid #fff;
  margin-left: 30px;
  padding: 5px 0;
  cursor: pointer;
  position: relative;
  bottom: 5px;
}