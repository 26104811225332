@pricing10: pricing10;
.@{pricing10}-wrapper {
  min-height: 760px;
  .@{pricing10} {
    >p {
      text-align: center;
    }
    &-content-wrapper {
      min-height: 400px;
    }
    &-block-box {
      width: 200px;
      height: 350px;
      border-radius: 4px;
      border-radius:8px;
      text-align: center;
      color: #666;
      min-height: 300px;
      margin: auto;
      border: 1px solid transparent;
      &:hover {
        width:250px;
        border:1px solid #418FFF;
      }
      &:hover .pricing10-name {
        margin-left: 85px;
        
      }
      &:hover .pricing10-money {
        margin-left: 70px;
        color: #418FFF;
      }
      &:hover .pricing10-icon1 {
        display: none;
      }
      &:hover .pricing10-icon2 {
        display: block;
      }
      .page-pro();
      &.active {
        border-color: #fff;
        background: #fff;
        .@{pricing10} {
          &-top-wrapper {
            background:linear-gradient(360deg,rgba(24,144,255,1) 0%,rgba(101,181,255,1) 100%);
          }
          &-name,
          &-money,
          &-button {
            color: #fff;
          }
          &-button {
            background: @primary-color;
          }
        }
      }
    }
    &-block {
      margin-bottom: 24px;
    }
    &-top-wrapper {
      width: 200px;
      padding: 16px 24px;
      position: relative;
      right: 1px;
      bottom: 1px;
      height: 170px;
    }
    &-name {
      font-size: 14px;
      position: relative;
      top: 40px;
    }
    &-money {
      font-family: 'Helvetica Neue', sans-serif;
      font-size:14px;
      color: #666;
      position: relative;
      top: 50px;
    }
    &-content {
      font-size: 14px;
      line-height: 2;
      font-weight: 300;
      margin: 32px 24px 48px;
      color: #595959;
    }
    &-line {
      display: none;
    }
    &-button-wrapper {
      display: none;
    }
    &-button {
      padding: 0 24px;
    }
  }
  &.home-page-wrapper {
    .@{pricing10}-title-wrapper {
      margin-bottom: 64px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{pricing10}-wrapper {
    padding-bottom: 0;
  }
}

.pricing10-subtitle {
  padding-top: 20px;
  text-align: center;
  width: 80%;
  padding-left: 21%;
}

.pricing10-icon1 {
  display: block;
  padding-left: 50px;
}

.pricing10-icon2 {
  display: none;
}