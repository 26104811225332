
.divide {
  height: 1px;
}
.footer1-wrapper {
  background: #2E3748;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: @template-footer-text-color;
  .footer1 {
    .home-page {
      padding: 64px 24px 80px;
    }
  }
  .block {
    padding: 0 32px;
    margin-right: 20px;
    .logo {
      max-width: 180px;
    }
    .slogan {
      font-size: 12px;
      margin-top: -20px;
      text-align: center;
    }
    >h2 {
      margin-bottom: 4px;
      color: @template-text-color;
    }
    a {
      color: @template-footer-text-color;
      margin-bottom: 4px;
      float: left;
      clear: both;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid fade(@line-color, 10);
    .home-page {
      padding: 0 24px;
      overflow: hidden;
    }
    .copyright {
      padding: 20px 0;
      height: 80px;
      text-align: center;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
    &-wrapper {
      .footer1 {
        .home-page {
          padding: 64px 24px 32px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;
    }
    >ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      >li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .home-page {
          padding: 0;
          .copyright {
            font-size: 12px;
          }
        }
        background-color: #2D313B;
      }

      span {
        width: 90%;
      }
    }
  }
}

.footer1-relavant {
  font-size: 18px;
  color: #D8D8D8;
  padding-bottom: 20px;
  text-align: left;
}

.footer1-relavant-text {
  color: #999!important;;
  font-size:12px;
}

.footer1-number {
  font-size: 36px;
  color: #fff;
}

.footer1-time {
  font-size: 16px;
  color: #fff;
  padding-top: 20px!important;
}

.footer1-hotline {
  font-size: 16px;
  color: #fff;
  padding-top: 50px;
  display: inline-block;
}

.footer1-wx {
  font-size: 16px;
  color: #CCCCCC;
}

.footer1-logoimg {
  padding-bottom: 10px;
}

.footer1-text-center {
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 90vw;
  color: #999!important;
}

.footer1-relavant-center {
  text-align: center;
  
}