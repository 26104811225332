
@content131: content131;
.@{content131}-wrapper {
  min-height: 380px;
  // background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  
  margin: 0 auto;
  overflow: hidden;
  padding: 70px 0 70px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
      .title-h1 {
        background: url("../../../img/home/news.png") no-repeat;
        background-position: center 10px;
        padding: 20px 0 0 0;
      }
    }
  }
  .title-content {
    line-height: 32px;
    padding-top: 10px;
    padding-bottom: 80px;
    color: #7B8390;
    font-size: 18px;
  }
}

@media screen and (max-width: 967px) {
  .@{content131}-wrapper {
    padding-bottom: 0;
  }
}

.content131-solution-wrapper {
  padding: 30px 20px;
  background:rgba(255,255,255,1);
  box-shadow:8px 4px 30px 0px rgba(0,0,0,0.1);
  border-radius:14px;
  height: 270px;
  // margin-right: 150px;
  // position: relative;
  // right: 300px;
  margin-right: 15%;
  position: relative;
  left: 58%;
}

.content131-img-wrapper {
  display: flex;
  justify-content: center;
}

.content131-solution-img {
  width:76px;
  height:76px;
  margin-top: 10px;
}

.content131-solution-title {
  font-size:24px;
  text-align: center;
  color: #4A4A4A;
  padding-bottom: 10px;
}

.content131-solution-content {
  color:rgba(74,74,74,1);
  line-height:20px;
  font-size: 14px;
  text-align: left;
  text-indent: 28px;
}

.content131-slider-wrapper {
  padding-bottom: 30px;
}

.content131-item-wrapper {
  width:520px;
  height:120px;
  border-radius:2px;
  display: flex;
  border:1px solid rgba(241,241,241,1);
  padding: 10px 10px 17px 10px;
  cursor: pointer;
}

.content131-item-wrapper:hover {
  background:rgba(255,255,255,1)  linear-gradient(136deg,rgba(107,168,255,1) 0%,rgba(65,143,255,1) 100%);
}

.content131-item-wrapper:hover .content131-news-title {
  color: #fff;
}

.content131-item-wrapper:hover .content131-news-text {
  color: #fff;
}

.content131-item-wrapper:hover .content131-left {
  color: #fff;
}

.content131-left {
  box-shadow: 1px 0px 0px 0px rgba(241,241,241,1);
  // display: inline-block;
  width: 110px;
  padding-right: 5px;
  
  color: #999999;
}

.content131-right {
  width: 350px; 
  display: inline-block;
  padding-top: 10px;
  text-align: left;
  padding-left: 15px;;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content131-time-day {
  font-size: 48px;
  position: relative;
  bottom: 10px;
}

.content131-time-year {
  font-size: 14px;
  position: relative;
  bottom: 10px;
}

.content131-news-title {
  font-size: 16px;
  color: #383838;
  margin-bottom: 10px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.content-news-text {
  font-size: 14px;
  color: #999999;
}

.content131-more {
  color: #418FFF;
  font-size: 14px;
  margin-top: 50px;
}