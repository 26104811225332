@content6: content6;
.@{content6}-wrapper {
  min-height: 620px;
  .@{content6} {
    height: 100%;
    display: flex;
    align-items: center;

    &-text {
      min-height: 424px;
      >*.queue-anim-leaving {
        position: relative !important;
      }
      .title-wrapper {
        
      }
      .title-h1 {
        position: relative;
        margin: 0 0 16px;
        text-align: center;
        font-size: 2em;
        background: url("../../../img/home/service.png") no-repeat;
        background-position: center 10px;
        padding: 20px 0 0 0;
      }
      .title-content {
        position: relative;
        margin-bottom: 64px;
        text-align: center;
        color: #7B8390;
        font-size: 18px;
      }
      ul {
        position: relative;
        display: inline-block;
        li {
          margin-bottom: 24px;
          .@{content6}-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            position: absolute;
          }
          .@{content6}-title,
          .@{content6}-content {
            margin-left: 45px;
          }
          .@{content6}-title {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .@{content6}-content {
            color: #999999;
            font-size: 14px;
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content6}-wrapper {
    height: 860px;
    overflow: hidden;
    .@{content6} {
      display: block;
      &-img,
      &-text {
        display: block;
        width: 100%;
      }
      &-text {
        >h1,
        >p {
          text-align: center;
        }
        > h1 {
          margin: 56px auto 16px;
        }
        p {
          margin-bottom: 32px;
        }
      }
      &-img {
        margin-top: 20px;
      }
    }
    .content6-item-wrapper {
      width: 100%;
    }
    .content6-left-wrapper {
      margin-left: 0;
      width: 100%;
      height: auto;
    }
  }
}

.content6-item-wrapper {
  border-bottom: 1px solid #DFE4EA;
  padding: 20px 0;
  width: 500PX;
}

.content6-item-wrapper:hover {
  border-bottom: 1px solid #418FFF;
}

.content6-item-wrapper:hover .content6-content{
  display: block!important;
}

.content6-item-wrapper:hover .content6-icon1{
  display: none;
}

.content6-item-wrapper:hover .content6-icon2{
  display: block;
}

.content6-icon1 {
  display: block;
}

.content6-icon2 {
  display: none;
}

.content6-left-wrapper {
  height: 400px;
  margin-left: 25%;
}
